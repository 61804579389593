<template>
    <div class="product_page">
        <van-nav-bar title="产品" left-text="返回" left-arrow @click-left="onClickLeft" />
        <div class="banner">
            <van-swipe :autoplay="3000" lazy-render indicator-color="#07C160">
                <van-swipe-item v-for="(item, index) in productImage" :key="index">
                    <img :src="item" />
                </van-swipe-item>
            </van-swipe>
        </div>
        <div class="product_info">
            <div class="title">{{ productInfo.title }}</div>
            <div class="intor">{{ productInfo.intro }}</div>
            <div class="price">
                <div class="money">{{ productInfo.money / 100 }}</div>
                <div class="sale">{{ productInfo.money / 100 }} 元/{{ productInfo.unit }}</div>
            </div>
        </div>
        <div class="product_desc">
            <van-tabs v-model:active="active">
                <van-tab title="产品详情">
                    <div class="product_content">
                        <div v-html="productInfo.content"></div>
                    </div>
                </van-tab>
                <van-tab title="下单须知">
                    <div class="order_content">
                        <div v-html="orderInfo"></div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <van-action-bar>
            <van-action-bar-icon icon="chat-o" text="客服" @click="onClickIcon" />
            <van-action-bar-button type="danger" text="立即购买" @click="toOrderBuy" />
        </van-action-bar>
    </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import request from '../../utils/request';
import { useRoute, useRouter } from "vue-router";
export default {
    setup() {
        const router = useRouter();
        const route = useRoute();
        const productId = ref(0);
        const productInfo = ref('');
        const orderInfo = ref('');
        const productImage = ref([]);
        const onClickLeft = () => history.back();
        const loadProductDetail = () => {
            request({
                url: '/app/product/' + productId.value,
                method: 'GET'
            }).then((res) => {
                if (res) {
                    productInfo.value = res.product
                    orderInfo.value = res.order
                    productImage.value = res.product.image
                }
            });
        };
        onBeforeMount(() => {
            productId.value = route.query.productId;
            if (productId.value) {
                loadProductDetail()
            }
        });
        const active = ref(0);
        const onClickIcon = ()=>{
            router.push({name:'Contact'})
        };
        const toOrderBuy = (id) => {
            router.push({name:"Cart",query:{productid:productId.value}})
        };
        return {
            onClickLeft,
            productInfo,
            orderInfo,
            productImage,
            active,
            onClickIcon,
            toOrderBuy,
        }
    },
}
</script>
<style>
.product_page {}

.product_page .banner {}

.product_page .banner img {
    width: 100%;
    height: auto;
}

.product_info {
    padding: 10px;
    border-bottom: 5px solid #ededed;
}

.product_info .title {
    font-size: 16px;
    margin-bottom: 15px;
}

.product_info .intor {
    font-size: 14px;
    margin-bottom: 15px;
    color: #999999;
}

.product_info .price {
    display: flex;
}

.product_info .price .money {
    font-size: 18px;
    font-weight: bold;
    color: #ee0a24;
    flex: 1;
}

.product_info .price .sale {
    font-size: 14px;
    color: #999999;
    width: 50%;
    text-align: right;
}

.product_desc {
    overflow: hidden;
}

.product_desc .product_content {
    overflow: hidden;
    padding: 10px 0px;
    display: block;
}

.product_desc .product_content p {
    margin: 0 0 -6px 0;
}

.product_desc .product_content img {
    width: 100%;
    height: auto;
}

.order_content {
    padding: 10px;
    font-size: 14px;
    color: #666666;
}</style>